.source-conditions-tag {
  display: grid;

  .conditions-group:not(:last-child) {
    margin-bottom: 24px;
  }

  .conditions-group {
    box-shadow: lightgray 0 1px 0 0;
    display: flex;
    width: inherit;
    flex-wrap: wrap;

    small {
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      right: 0;
      border-radius: 5px;
      background-color: rgba(211, 211, 211, 0.5);
      width: 20px;
      height: 20px;
      margin-top: 7px;
    }

    .ant-tag {
      margin: 5px 5px 5px 0;
    }
  }
  .pin {
    margin-bottom: 20px;
    button {
      &:hover {
        color: red;
      }
    }
  }
}
