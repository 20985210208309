.survey-name {
  display: grid;
  align-content: center;
  align-items: center;
  cursor: pointer;

  transition: 2s;
  &:before {
    transition: 2s;
  }
  &:hover {
    transition: 2s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 40px;
      width: 5px;
      background: #00AA5C;
      transition: 2s;
    }
  }

  .anticon {
    margin-right: 10px;
    font-size: 20px;
  }
}
