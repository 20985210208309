.page-layout {
  .filters {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;

    .tags-filter {
      margin-left: 24px;
      max-width: 400px;
      min-width: 300px;
    }
  }

  .filter-active {
    .anticon-filter {
      color: #00aa5c;
    }
  }
}

.ai-assistant-button:hover {
  & {
    background-color: #9f6de0 !important;
  }
}

.share-page-icon-button {
  transition: 200ms;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px 0 !important;
  }
}
