.status-filters {
  display: grid;
  padding: 5px;

  .ant-checkbox-wrapper {
    margin-inline-start: 0;
  }

  label {
    padding: 5px 10px;
    border-radius: 5px;
    margin-inline-end: 0;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.filter-action-btns {
  padding: 0 11px;

  button {
    &:first-child {
      width: auto;
    }
    &:last-child {
      width: auto;
    }
  }
}
