.contacts-page-layout {
  .filters {
    margin-bottom: 20px;
  }
}

.contact-filters-upload-list-styles {
  .ant-upload-list-item {
    max-width: 611px;
  }
}
