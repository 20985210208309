body {
  background-color: #f0f2f5 !important;
}

a {
  color: #00aa5c;

  span {
    color: #00aa5c;
  }
}

* {
  &:not(.dont-use-global-font) {
    font-family: "Golos Text", sans-serif !important;
    button {
      font-family: "Golos Text Light", sans-serif !important;
      font-weight: 300 !important;
      span {
        font-family: "Golos Text Light", sans-serif !important;
        font-weight: 300 !important;
      }
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-form-item-label > label {
    font-family: "Golos Text Light", sans-serif !important;
    font-size: 13px !important;
    color: #9296a1 !important;
    font-weight: 400 !important;
    bottom: -3px;
    text-align: start !important;
    display: flex !important;
  }
}
.ant-popconfirm-buttons {
  display: flex;
  justify-content: flex-end;
}

.ant-btn-default:hover {
  background-color: #d6efe4;
}

button[disabled] {
  border: none;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 7px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #697a7f;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.ant-layout-content {
  min-height: 60%;
}

.unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.ant-popover-inner-content {
  padding: 5px !important;

  .ant-btn {
    text-align: left;
  }
}

.ant-checkbox-wrapper-in-form-item {
  margin-bottom: 10px;
}

.ant-btn-link {
  padding-left: 0 !important;
  margin-bottom: 15px;
  span {
    text-decoration: underline;
  }
}

.hide-label {
  label {
    visibility: hidden;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;

  .ant-spin-dot {
    top: 45% !important;
  }
}

.disabled {
  .ant-btn,
  .ant-input-affix-wrapper,
  .ant-select,
  .ant-tag,
  .ant-switch,
  .ant-table-tbody,
  .ant-table-selection-column,
  .ant-col {
    pointer-events: none;
    opacity: 0.8;
  }
}

.cursor-pointer {
  cursor: pointer !important;

  span:last-child {
    margin-left: 10px;
  }
}

.ant-form-item-label > label {
  text-align: initial;
}

.button-text-style {
  .ant-btn-text:hover {
    background-color: #ecedf0;
  }
  .ant-btn-text {
    background-color: #f5f6f8;
    margin-right: 10px;
  }

  button .anticon-plus {
    font-size: 16px;
  }
  button span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.ant-table-tbody {
  .ant-empty-normal {
    margin-bottom: 5px;
  }
}

.actions {
  .ant-btn-text:hover {
    background-color: #ecedf0;
  }
  .ant-btn-text {
    background-color: #f5f6f8;
    margin-right: 10px;
  }

  button .anticon-plus {
    font-size: 16px;
  }
  button span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.ant-form-item-label > label.ant-form-item-required:before {
  content: unset !important;
}

.ant-form-item-label > label.ant-form-item-required:after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 16px !important;
  line-height: 1;
  content: "*";
}

.custom-button {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
}

.ant-form-item {
  label {
    font-weight: 500;
    text-shadow: none;
  }
}

.ant-divider-with-text-left {
  &:before {
    width: 0 !important;
  }
  .ant-divider-inner-text {
    padding-left: 0;
  }
}

.ant-form-vertical .ant-form-item-label > label::after {
  visibility: visible;
}

.table-copy-btn {
  display: flex;
  align-items: center;

  .anticon-copy:hover {
    color: #1890ff;
    cursor: pointer;
  }

  .anticon-copy {
    svg {
      height: 18px;
      width: 18px;
    }
    margin-left: 10px;
  }
}

.ant-tour-buttons {
  display: flex;
}

.ant-modal-footer {
  display: flex;
  width: 100%;

  button {
    flex: 1;
  }
}
