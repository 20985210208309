.question-edit-page-layout {
  display: flex;

  .q-list {
    flex: 1;
    position: relative;
    top: 20px;
    min-width: 400px;

    .ant-list {
      max-height: 700px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .--list-item {
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        span {
          color: black;
          font-weight: 600;
        }
      }
    }
    .active {
      span {
        color: black;
        font-weight: 700;
      }
    }

    .new-active {
      span {
        color: #00aa5c !important;
        font-weight: 700;
      }
    }

    .inactive {
      span {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    span {
      cursor: pointer;
    }
  }

  .qe-content {
    flex: 4;
    box-shadow: rgba(0, 0, 0, 0.25) 0 25px 50px -12px;
    border-radius: 5px;
    padding: 30px 25px;

    .lang-tabs {
      margin-bottom: 0;
      font-size: 15px;

      .ant-tabs-tab-btn {
        font-size: 13px;
        font-weight: 500;
      }
    }

    .ant-tabs-nav::before {
      width: 0;
    }

    .lang-tab-content {
      border: 1px solid #f0f0f0;
      padding: 20px 20px 1px;
      position: relative;
      top: -17px;
      border-radius: 0 5px 5px 5px;
    }
  }
}

.questions-container {
  .questions-edit-page {
    .tags-container {
      label {
        margin-left: 40px;
      }
    }
    .form-container {
      form {
        .question-configurations-container {
          width: 100%;
        }
        .ant-form-item-explain-error {
          width: max-content;
        }
      }

      .features {
        position: absolute;
        left: 60px;
        bottom: 30px;

        .ant-btn-text:hover {
          background-color: #ecedf0;
        }
        .ant-btn-text {
          background-color: #f5f6f8;
          margin-right: 10px;
        }

        button .anticon-plus {
          font-size: 16px;
        }
        button span {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
      }

      .ant-form-item-label {
        min-width: 250px;
      }
      .ant-input-group {
        input {
          border-bottom-right-radius: 6px !important;
          border-top-right-radius: 6px !important;
        }
      }
      .input-group {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        input {
          width: 45%;

          &:first-child {
            margin-right: 20px;
          }
        }

        .anticon-minus-circle {
          margin-left: 12px;
        }
      }

      .no-error-label {
        .ant-form-item-explain-error {
          position: absolute;
          visibility: hidden;
        }
      }
    }
  }

  .answer-template-name {
    white-space: nowrap;
    .anticon {
      margin-right: 5px;
    }
  }

  .create-question-btn {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    button {
      margin: 10px 0 20px;
    }
  }

  .btn-actions {
    display: flex;
    text-align: right;
    justify-content: left;
    width: 620px;
    margin-top: 30px;
    gap: 10px;

    button {
      flex: 1;
    }
  }

  .link-name {
    display: flex;
    align-content: center;
    align-items: center;

    .link:hover {
      color: #1890ff;
    }

    .link {
      cursor: pointer;
    }

    .anticon {
      margin-right: 10px;
      font-size: 20px;
    }
  }
}

.params-input-group {
  display: flex;
  align-items: center;

  .anticon-minus-circle {
    margin-left: 5px;
    margin-top: 4px;
  }
}
