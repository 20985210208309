.survey-create-page {
  .ant-steps-horizontal {
    display: flex !important;
    align-items: center;

    .ant-steps-item {
      flex: 1;
    }
  }

  .disabled-color {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs {
    margin-bottom: 10px;

    .ant-tabs-tab {
      padding: 15px 15px 15px 0;
      font-size: 16px;
      font-weight: 700;
    }

    .ant-tabs-ink-bar {
      height: 4px;
    }
  }

  .steps-content {
    min-height: 350px;

    .ant-form-item-control-input-content {
      min-width: 370px;
      max-width: 370px;
    }

    .ant-form-item-label {
      min-width: 250px;
    }

    .ant-upload-wrapper {
      display: flex;

      .ant-upload-select {
        flex: 1;
      }

      .ant-upload-list-text {
        flex: 1;
        max-width: 200px;
      }
    }
  }

  .steps-action {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 620px;
    margin-top: 30px;

    button {
      flex: 1;
    }
  }
}

.status-tag {
  margin-left: auto;
  color: black;

  span:first-child {
    font-weight: 500;
  }

  .ant-tag {
    margin-left: 20px;
  }
}

.confirm-on-close-modal {
  .ant-modal-confirm-btns {
    text-align: center;
  }
}

.box {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;

  button {
    margin-right: 5px;
  }

  .unselectable {
    margin-left: 20px;
    padding-top: 3px;
  }
}

.add-custom-answer-btn {
  .ant-btn-text:hover {
    background-color: #ecedf0;
  }
  .ant-btn-text {
    background-color: #f5f6f8;
    margin-right: 10px;
  }

  button .anticon-plus {
    font-size: 16px;
  }
  button span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}
