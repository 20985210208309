.personalization-container {
  display: flex;

  .personalization-tab {
    .ant-form-item-control {
      min-width: 370px;
      max-width: 370px;
    }
    .ant-form-item-label {
      min-width: 250px;
    }

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &.nomargin {
        margin-bottom: 0 !important;
      }

      input[type="text"] {
        width: 370px;
      }

      h4 {
        margin: 0;
        width: 250px;
      }

      .hex-text {
        border: none;
        font-weight: 500;
        margin-left: 10px;
        max-width: 100px;
      }

      .required-mark {
        &:after {
          content: "*";
          color: #ff4d4f;
        }
      }
      .undo-icon:hover {
        color: #1890ff;
      }
      .undo-icon {
        cursor: pointer;
        margin-right: 10px;
      }

      input[type="color"] {
        border: 1px solid lightgray;
        background: none;
        border-radius: 3px;
        padding: 0;
        cursor: pointer;
      }

      button:first-child {
        margin-right: 10px;
      }

      button {
        flex: 1;
      }
    }

    .logo-info-alert {
      margin-bottom: 20px;
      width: 287px;

      small {
        display: grid;
        color: #6b6b6b;
      }
    }

    .ant-upload-list-item-name {
      max-width: 200px;
    }
  }
}
