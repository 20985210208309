.integrations-container {
  h3 {
    font-weight: 600;
  }

  .create-webhook-btn {
    display: flex;
    width: 100%;
    align-items: center;

    button {
      margin: 15px 0 30px auto;
    }
  }

  .btn-actions {
    text-align: right;

    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.webhook-edit-page {
  .form-container {
    .ant-form-item-control-input-content {
      min-width: 370px;
      max-width: 370px;
    }
    .ant-form-item-label {
      min-width: 250px;
    }

    .ant-input-group {
      input {
        border-bottom-right-radius: 6px !important;
        border-top-right-radius: 6px !important;
      }
    }
    .input-group {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      input {
        width: 45%;

        &:first-child {
          margin-right: 20px;
        }
      }

      .anticon-minus-circle {
        margin-left: 12px;
      }
    }

    .choices-input-group {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      input {
        width: 45%;
        background: #0e0e23;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .no-error-label {
      .ant-form-item-explain-error {
        position: absolute;
        visibility: hidden;
      }
    }
  }
}
