.calculate-btn {
  position: absolute;
  left: 60px;
  bottom: 15px;
}

.qr-code-container {
  width: 65%;
  min-height: 400px;

  .qr-image {
    margin: 50px 0;
    display: grid;
    justify-content: flex-start;

    img {
      max-height: 500px;
    }
  }
}

