.phone__small {
  height: 339px;
  width: 157px;
  border: 6px solid #080808;
  border-radius: 22px;

  .button {
    width: 90%;
    margin-bottom: 10px;

    .ant-btn {
      width: 100%;
      border-radius: 6px;
      height: 25px;
      background-color: var(--app-color);
      border-color: var(--app-color);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 600;
        font-size: 10px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: black;
      }
    }
  }

  &:before {
    height: 14px;
    border-radius: 0 0 7px 7px;
    left: calc(50% - 28%);
    top: 0;
    width: 56%;
  }

  .container {
    .texts {
      h3 {
        padding: 10px;
        font-weight: 700;
      }

      p {
        padding: 10px;
        font-size: 10px;
      }
    }

    .content {
      display: grid;
      padding: 10px;

      .item {
        span {
          font-size: 11px;
        }

        .ant-checkbox-wrapper {
          align-items: self-end;
        }
        .ant-radio-wrapper {
          align-items: center;
        }
        .ant-checkbox .ant-checkbox-inner,
        .ant-radio .ant-radio-inner {
          -ms-transform: scale(0.7);
          -webkit-transform: scale(0.7);
          transform: scale(0.7);

          background-color: var(--app-color);
          border-color: var(--app-color);
        }

        .ant-radio-checked .ant-radio-inner {
          background-color: white;
        }

        .ant-radio-checked .ant-radio-inner:after {
          background-color: var(--app-color);
        }
      }
    }

    .header {
      height: 35px;
      margin-top: 15px;

      .lang-select {
        border-radius: 2px;
        margin-right: 10px;
        padding: 4px 4px;

        svg {
          height: 8px;
        }

        span {
          font-size: 10px;
          font-weight: 600;
        }
      }

      .logo {
        margin-left: 5px;
        height: auto;
        max-width: 60px;
        max-height: 28px;

        img {
          height: 50px;
        }
      }
    }
  }
}

.phone__large {
  height: 470px;
  width: 250px;
  border: 9px solid #080808;
  margin-left: 50px;
  border-radius: 40px;

  .communications {
    position: absolute;
    width: 100%;
    height: 26px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    .clock {
      position: relative;
      font-size: 13px;
      font-weight: bold;
      left: 23px;
    }

    .anticon {
      font-size: 10px;
    }

    .anticon-signal {
      margin-left: auto;
      margin-right: 4px;
    }

    .anticon-wifi {
      margin-right: 4px;
    }

    .battery {
      border: 1px solid gray;
      margin-right: 18px;
      font-size: 9px;
      font-weight: 600;
      color: gray;
      border-radius: 3px;
      height: 12px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0;

      &:after {
        position: absolute;
        height: 8px;
        width: 2px;
        background-color: #878383;
        content: "";
        right: 17px;
        border-radius: 35px;
      }
    }
  }

  .button {
    width: 95%;
    margin-bottom: 25px;

    .ant-btn {
      border-radius: 10px;
      height: 40px;
    }
  }

  &:before {
    left: 34%;
    top: 4px;
    width: 30%;
    height: 17px;
    border-radius: 10em;
  }

  .container {

    .phone-content-scrollable-container {
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .rating-container {
      display: flex;
      justify-content: center;

      .ant-rate-star {
        margin-inline-end: 5px;
      }
    }

    .loyalty-button-group {
      width: 100%;
      margin-bottom: 10px;

      .loyalty-button {
        font-size: 10px;
        padding: 4px 8px;
        width: 18px;
        height: auto

      }
    }

    .texts {
      position: relative;

      h3 {
        padding: 0 20px;
        font-weight: bold;
        margin: 0 0 10px;
        max-width: 230px;
        overflow: hidden;

        font-family: var(--title-font) !important;
      }

      p {
        padding: 0 20px;
        font-weight: lighter;
        margin: 0 0 10px;
        max-width: 230px;
        overflow: hidden;
        line-height: 20px;
        font-family: var(--text-font) !important;
      }
    }

    .phone-answer-template-content {
      padding: 20px 0 20px 20px;
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;

      .nps-template {
        .grade-text {
          display: flex;

          span:first-child {
            min-width: 40px;
          }

          span {
            padding: 4px 0;
          }
        }

        .ant-slider-rail,
        .ant-slider-track,
        .ant-slider-step {
          height: 10px;
        }
        .ant-slider-dot {
          inset-block-start: -1px;
          width: 12px;
          height: 12px;
        }
        .ant-slider-handle {
          inset-block-start: 4px;
          width: 12px;
          height: 12px;
        }
        .ant-slider-handle::after {
          inset-block-start: -2px;
          inset-inline-start: 0;
          width: 13px;
          height: 13px;
          background-color: #ffffff;
          box-shadow: 0 0 0 2px var(--app-color);
        }

        .lowest-highest-text-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 8px;

          .lowest-text {
            overflow: hidden;
            width: 45%;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            word-break: break-word;

          }

          .highest-text {
            overflow: hidden;
            width: 45%;
            overflow-wrap: break-word;
            text-align: right;
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
      }

      .csi-template {
        .csi-item {
          display: grid;
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(211, 211, 211, 0.15);

          h4 {
            font-weight: 600;
          }
          .criteria-container {
            .criteria-item {
              display: flex;
              padding-bottom: 10px;
              align-items: center;

              h5 {
                font-weight: 500;
              }

              .criteria-select {
                margin-left: auto;
                background: rgba(17, 17, 20, 0.04);
                border-radius: 6px;
                width: 55px;

                .ant-select-selector {
                  height: 30px;
                  align-items: center;
                }

                span {
                  font-weight: 500;
                  font-size: 12px;
                  padding-inline-end: 0;
                }
              }
            }
          }
        }
      }

      .required-mark {
        position: absolute;
        font-size: 20px;
        color: red;
        left: 10px;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--app-color) !important;
        border-color: var(--inverted-color);

        &:after {
          fill: var(--inverted-color);
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: var(--inverted-color);
        background-color: var(--app-color);
      }

      .ant-radio-checked .ant-radio-inner:after {
        background-color: var(--inverted-color);
      }

      .ant-input:focus {
        border-bottom: 1px solid var(--app-color);
      }

      .ant-input {
        border-bottom: 1px solid lightgray;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .content {
      display: grid;
      padding: 20px;
    }

    .header {
      height: 45px;
      margin-top: 27px;
      max-width: 233px;

      .lang-select {
        border-radius: 3px;
        margin-right: 10px;
        padding: 2px 4px;

        svg {
          height: 12px;
        }

        span {
          font-size: 11px;
          font-weight: 500;
        }
      }

      .logo {
        margin-left: 10px;
        height: auto;
        max-width: 40%;
        max-height: 80%;

        img {
          height: 50px;
        }
      }
    }
  }
}

.iphone-x {
  display: inline-block;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background-color: #ececec;

  position: absolute;

  &:before {
    position: absolute;
    background: #080808;
    content: "";
  }

  .container {
    display: grid;
    height: 100%;

    .content {
      display: grid;

      .item {
        span {
          font-size: 11px;
        }

        .ant-checkbox-wrapper {
          align-items: self-end;
        }
        .ant-radio-wrapper {
          align-items: center;
        }
        .ant-checkbox .ant-checkbox-inner,
        .ant-radio .ant-radio-inner {
          -ms-transform: scale(0.7);
          -webkit-transform: scale(0.7);
          transform: scale(0.7);

          background-color: var(--app-color);
          border-color: var(--app-color);
        }

        .ant-radio-checked .ant-radio-inner {
          background-color: white;
        }

        .ant-radio-checked .ant-radio-inner:after {
          background-color: var(--app-color);
        }
      }
    }

    .header {
      width: 100%;
      display: flex;
      background-color: var(--app-color);
      align-items: center;

      .lang-select {
        display: flex;
        align-items: center;
        background: var(--app-color);
        margin-left: auto;
      }
    }
    .language-changer {
      margin-left: auto;

      span {
        font-weight: 500;
      }
    }

    .button {
      margin-left: 7px;
      margin-top: auto;

      .ant-btn {
        width: 100%;
        background-color: var(--app-color);
        border-color: var(--app-color);
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}

.phone-back-btn-line {
  width: 100px;
  height: 5px;
  background: #a0a0a0;
  position: absolute;
  bottom: 5px;
  left: 66px;
  border-radius: 5px;
}

.phone-answer-template-checkbox {
  display: grid;

  label {
    margin-bottom: 7px;
  }

  .ant-checkbox-wrapper {
    margin-inline-start: 0;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    border: 2px solid var(--inverted-color);
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover,
  .ant-checkbox-inner,
  .ant-checkbox-inner {
    border-color: #e0e0e0;
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border: none;
  }
}
