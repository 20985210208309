.tag-add-btn {
  background: #fff;
  border-style: dashed;
  cursor: pointer;
  border-color: #00AA5C;
  color: #00AA5C;

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
      rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
  }
}

.tags-action-btns {
  position: absolute;
  left: -30px;
  top: 1px;

  button {
  }
}

.tag-checked {
  background-color: #00AA5C;
  color: white;

  .anticon-close {
    color: white;
  }
}

.days-form-container {
  display: flex;

  .ant-checkbox-wrapper-in-form-item {
    height: 10px;

    .ant-checkbox + span {
      padding: 0 4px;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}

.ant-picker-ranges {
  display: none;
}

.upload-parent {
  .ant-upload {
    width: 100%
  }
}