.editor-container {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  line-height: 1.2;
  overflow: auto;

  .contentEditable {
    height: 150px;
    overflow-y: auto;
  }

  &.rows-2 .contentEditable {
    height: 86px;
  }

  &.rows-5 .contentEditable {
    height: 180px;
  }

  &.editor-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
