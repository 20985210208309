$bg-color: #ffffff;
$body-color: #ffffff;
$main-bg: #ffffff;
$max-height: 60px;

.password-change-modal {
  position: absolute;
  right: 75px;
  top: 75px;
}

.unauthorized {
  .header {
    height: 71px;
  }
}

.app-header {
  color: $body-color;

  .wrapper {
    background-color: $main-bg;
    display: flex;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  }

  .header {
    background: #ffffff;

    width: 100%;
    padding: 0 60px;
    animation: top 1s both;
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    &-link {
      color: black;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 20px;
      transition: 0.3s;
      margin-right: 10px;
      border-top: 4px solid transparent;
      font-weight: 500;

      &.active,
      &:hover {
        color: #00AA5C;
        margin-right: 10px;
        background: #ffffff;
        border-top: 5px solid #00AA5C;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    font-size: 17px;
    cursor: pointer;

    img {
      max-height: 45px;
    }
  }

  .box {
    margin-left: auto;
    display: flex;
    align-items: center;

    button {
      margin: 0;
    }
  }

  .user-info {
    margin-left: 20px;
  }

  .button:hover {
    transition: 0.3s;
  }

  .button {
    display: flex;
    align-items: center;

    .lang-label {
      font-weight: 500;
      margin-right: 10px;
    }
    .user-name {
      margin: 0 10px;
      font-weight: 500;
      width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.header-actions-menu-btn {
  text-align: center;
}

.app-footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 60px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 4px;

  .links {
    margin-top: 10px;
    text-align: center;
    color: #999;
  }

  .links a {
    color: #333;
    margin: 0 10px;
    text-decoration: none;
  }

  .links span {
    color: #999;
    margin: 0 10px;
    cursor: pointer;
  }

  .lang-change-select {
    float: left;
  }

  .lang-label {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
}
