.distributions-content-container {
  display: grid;
  width: 100%;

  .title-button-container {
    display: flex;
    width: inherit;

    h2 {
      font-weight: 500;
    }

    button {
      margin-left: auto;
    }
  }

  .table {
    width: inherit;
    margin-top: 24px;
  }
}

.distribution-edit-modal-label-margin {
  margin-top: 30px;
  .ant-form-item-label {
    min-width: 350px;
  }

  .ant-form-item-control {
    min-width: 400px;
  }
}
